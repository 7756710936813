import React from 'react';
import { TextWithMedia as Organism } from '@components/Organisms';

export const TextWithMedia = ({
  display,
  media,
  title,
  internalLink,
  subtitle,
  subIcons,
  bodyContent,
}) => {
  return display ? (
    <Organism
      display={display}
      media={media}
      title={title}
      internalLink={internalLink}
      subtitle={subtitle}
      subIcons={subIcons}
      bodyContent={bodyContent}
    />
  ) : null;
};
